<template>
  <v-card class="pa-3 mb-3">
    <h2 class="title">{{$t('facturas.adjuntar')}}</h2>
    <v-divider class="mb-2"></v-divider>
    <DragAndDrop accept="application/pdf" v-model="doc" />
    <template v-if="doc">
      <v-divider class="my-2"></v-divider>
      <v-card outlined class="pa-2">
        <v-card-title>{{ doc.name }}</v-card-title>
      </v-card>
      <div class="d-flex mt-2">
        <v-btn @click.stop="doc = null" color="error darken-2" small>
          <v-icon left>mdi-close</v-icon>
          {{ $t('common.quitar') }}
        </v-btn>
        <v-btn @click.stop="enviar" color="secondary" class="ml-auto" small>
          <v-icon left>mdi-upload</v-icon>
          {{ $t('common.editar') }}
        </v-btn>
      </div>
    </template>
  </v-card>
</template>

<script>
import { downloadFile } from "@/utils/index.js";

export default {
  components: {
    DragAndDrop: () => import("@/components/DragAndDrop.vue"),
  },
  props: {
    factura: Object,
  },
  data() {
    return {
      doc: null,
    };
  },
  methods: {
    async enviar() {
      axios({
        method: "POST",
        url: `/facturas/documento/${this.factura.ref}`,
        headers: { "Content-Type": "multipart/form-data" },
        data: {
          file: this.doc,
        },
      })
        .then(() => {
          this.$emit("reload");
        })
        .catch(() => {
          this.$root.$emit("snack", this.$t('documentacion.error_subir') );
        });
    },
  },
};
</script>

<style></style>
